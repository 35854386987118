<template>
  <v-dialog transition="dialog-top-transition" v-model="show" max-width="600px" >

    <v-card>
      <v-card-title class="blue darken-2 white--text mb-4">
        <span>Editar curso</span>
      </v-card-title>

      <v-card-text class="pt-3">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="editCourse.name"
              label="Nombre*"
              outlined dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editCourse.slots"
              label="Cupos*"
              type="number"
              min="1"
              max="100"
              outlined dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="editCourse.professorName"
              label="Nombre del Profesor*"
              outlined dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editCourse.professorEmail"
              label="Email del Profesor"
              outlined dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="show = false" >
          Cancelar
        </v-btn>
        <v-btn color="success" @click="saveEditCourse()" :disabled="isSaving" :loading="isSaving">
          Añadir
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </v-dialog>
</template>

<script>

export default {
  name: "EditRegistrationCourseDialog",
  props:{
    value: Boolean,
    courseData: {},
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',    
      //form
      editCourse: {
        name: '',
        professorName: '',
        professorEmail: '',
        slots: 20,
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    show() {
      if(this.show) this.cloneCourseData()
    }
  },
  methods: {
    cloneCourseData() {
      if(this.courseData) {
        this.editCourse.name = this.courseData.name
        this.editCourse.slots = this.courseData.slots
        this.editCourse.professorName = this.courseData.professorName
        this.editCourse.professorEmail = this.courseData.professorEmail
      }
    },
    async saveEditCourse() {
      try {

        if(!this.editCourse.name || !this.editCourse.professorName || this.editCourse.slots < 1) return this.invalidFormNotification()

        this.isSaving = true

        this.$emit('edit', this.editCourse)
        this.show = false

        this.editCourse = {
          name: '',
          professorName: '',
          professorEmail: '',
          slots: 20,
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al editar el curso'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete los campos requeridos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>