<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="400px" >

      <v-card>

        <v-card-title class="text-body-1 mb-2" style="word-break: break-word;">Desea importar los cursos existentes a este proceso de inscripción?</v-card-title>

        <v-card-text>

          <p>Atención: Esto sobrescribirá los cursos adheridos en este proceso de inscripción</p>

          <v-text-field
            v-model="slots"
            label="Cupos por curso"
            hint="Indique la cantidad de cupos que se asignarán por curso"
            type="number"
            min="1"
            max="100"
            outlined dense
            persistent-hint
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="importCourses()" :loading="isSaving">
            Importar Cursos
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
export default {
  name: 'ImportCoursesDialog',
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //data
      slots: 20,
    }
  },
  methods: {
    async importCourses() {
      try {

        this.isLoading = true

        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/course/all`)

        if(resp.data.data.length < 1) {
          this.snackcolor = 'warning'
          this.snacktext = 'No hay cursos para importar'
          this.isSnackbar = true
          this.show = false
          return
        }

        const formattedCourses = resp.data.data.map(course => {
          return {
            name: course.name,
            professorName: course.professor.name,
            professorEmail: course.professor.email,
            slots: this.slots,
          }
        })

        this.$emit('import', formattedCourses)
        this.show = false

      } catch (e) {
        console.log(e)
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al importar los cursos'
        this.isSnackbar = true
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>